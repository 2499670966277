import * as types from './constants';

export const openModalAction = (modalId, modalProps) => ({
  type: types.OPEN_MODAL,
  payload: {
    modalId,
    modalProps,
  },
});

export const closeModalAction = () => ({
  type: types.CLOSE_MODAL,
});

export const setButtonPropsAction = buttonProps => ({
  type: types.SET_BUTTON_PROPS,
  payload: buttonProps,
});

export const setForceCloseOnboardingAction = forceCloseOnboarding => ({
  type: types.SET_FORCE_CLOSE_ONBOARDING,
  payload: forceCloseOnboarding,
});

export const setOnboardingSaveOnlyMode = saveOnly => ({
  type: types.SET_ONBOARDING_SAVE_ONLY_MODE,
  payload: saveOnly,
});

export const setOnboardingActiveSlide = slide => ({
  type: types.SET_ONBOARDING_ACTIVE_SLIDE,
  payload: slide,
});

export const openOnboardingPublishModal = () => ({
  type: types.OPEN_ONBOARDING_PUBLISH_MODAL,
});
