export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_BUTTON_PROPS = 'SET_BUTTON_PROPS';

//  modals
export const SCHEDULE_LOOK_MODAL = 'SCHEDULE_LOOK_MODAL';

// onboarding
export const SET_FORCE_CLOSE_ONBOARDING = 'SET_FORCE_CLOSE_ONBOARDING';
export const SET_ONBOARDING_SAVE_ONLY_MODE = 'SET_ONBOARDING_MODE';
export const SET_ONBOARDING_ACTIVE_SLIDE = 'SET_ONBOARDING_ACTIVE_SLIDE';
export const OPEN_ONBOARDING_PUBLISH_MODAL = 'OPEN_ONBOARDING_PUBLISH_MODAL';
