import produce from 'immer';
import * as types from './constants';

export const initialState = {
  modalId: '',
  modalProps: {
    onClose: undefined,
    description: '',
    heading: '',
    closeLabel: '',
  },
  forceCloseOnboarding: false,
  onboardingSaveOnlyMode: true,
  onboardingActiveSlide: 0,
};

const modalReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.OPEN_MODAL:
        draft.modalId = payload.modalId;
        draft.modalProps = { ...initialState.modalProps, ...payload.modalProps };
        break;

      case types.CLOSE_MODAL:
        draft.modalId = '';
        draft.modalProps = { ...initialState.modalProps };
        break;

      case types.SET_BUTTON_PROPS:
        draft.modalProps = { ...draft.modalProps, buttonsProps: payload };
        break;

      case types.SET_FORCE_CLOSE_ONBOARDING:
        draft.forceCloseOnboarding = payload;
        break;

      case types.SET_ONBOARDING_SAVE_ONLY_MODE:
        draft.onboardingSaveOnlyMode = payload;
        break;

      case types.SET_ONBOARDING_ACTIVE_SLIDE:
        draft.onboardingActiveSlide = payload;
        break;

      case types.OPEN_ONBOARDING_PUBLISH_MODAL:
        draft.onboardingSaveOnlyMode = false;
        draft.onboardingActiveSlide = 0;
        draft.forceCloseOnboarding = false;
        break;
      default:
        return state;
    }
  });

export default modalReducer;
