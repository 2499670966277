import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'components';
import { useHistory } from 'react-router-dom';
import LooksService from '../../api/services/LooksService';
import { discontinuedProductsSelector } from '../../redux/auth/selectors';
import { LOOKS } from '../../constants/routes';

const DiscontinuedProductsPopupWrapper = () => {
  const discontinuedProducts = useSelector(discontinuedProductsSelector);
  const history = useHistory();

  const stores = (
    discontinuedProducts?.filter(product => !product.is_favorite_product) || []
  ).slice();
  const favorites = (
    discontinuedProducts?.filter(product => product.is_favorite_product) || []
  ).slice();

  const [popupState, setPopupState] = useState({
    showStoresPopup: false,
    showFavoritesPopup: false,
    isStoresPopupClosed: false,
  });

  useEffect(() => {
    if (!popupState.showStoresPopup && !popupState.isStoresPopupClosed && stores.length > 0) {
      setPopupState(prev => ({
        ...prev,
        showStoresPopup: true,
      }));
    } else if (stores.length === 0 && !popupState.isStoresPopupClosed) {
      setPopupState(prev => ({
        ...prev,
        isStoresPopupClosed: true,
      }));
    }
  }, [discontinuedProducts]);

  // Show favorites popup only after stores popup is closed (if there are favorites)
  useEffect(() => {
    if (popupState.isStoresPopupClosed && !popupState.showFavoritesPopup && favorites.length > 0) {
      setPopupState(prev => ({
        ...prev,
        showFavoritesPopup: true,
      }));
    }
  }, [popupState.isStoresPopupClosed, favorites.length]);

  const handleCloseStores = async () => {
    setPopupState(prev => ({
      ...prev,
      showStoresPopup: false,
      isStoresPopupClosed: true,
    }));

    await LooksService.markDiscontinuedProductsAsRead({ isFavoriteProduct: false });
    history.push(LOOKS);
  };

  const handleCloseFavorites = async () => {
    setPopupState(prev => ({
      ...prev,
      showFavoritesPopup: false,
    }));

    await LooksService.markDiscontinuedProductsAsRead({ isFavoriteProduct: true });
  };

  // Rendering for Stores Popup
  const renderStoresModalContent = items => (
    <Modal.ClosableBody>
      {({ closePopup }) => (
        <div className="d-flex flex-column">
          <p>
            One or more products in your looks have been discontinued. Please review and update your
            looks before republishing.
          </p>
          <ul>
            {items.map(item => (
              <li>
                {item.product_name} - {item.store_name || 'Unknown Look'}
              </li>
            ))}
          </ul>
          <p>Edit your looks and republish to ensure they remain up to date.</p>
          <Modal.Footer>
            {() => (
              <Button
                style={{ float: 'right' }}
                variant="dark"
                onClick={async () => {
                  await closePopup();
                }}
              >
                OK
              </Button>
            )}
          </Modal.Footer>
        </div>
      )}
    </Modal.ClosableBody>
  );

  // Rendering for Favorites Popup
  const renderFavoritesModalContent = items => (
    <Modal.ClosableBody>
      {({ closePopup }) => (
        <div className="d-flex flex-column">
          <p>
            Your product{items.length > 1 ? 's' : ''} from favorite products{' '}
            {items.length > 1 ? 'are' : 'is'} discontinued and no longer available in your favorite
            products.
          </p>
          <ul>
            {items.map(item => (
              <li>{item.product_name}</li>
            ))}
          </ul>
          <Modal.Footer>
            {() => (
              <Button
                style={{ float: 'right' }}
                variant="dark"
                onClick={async () => {
                  await closePopup();
                }}
              >
                OK
              </Button>
            )}
          </Modal.Footer>
        </div>
      )}
    </Modal.ClosableBody>
  );

  return (
    <>
      {popupState.showStoresPopup && stores.length > 0 && (
        <Modal
          open={popupState.showStoresPopup}
          className="w-100"
          header={{
            title: 'Notice: Products Discontinued',
          }}
          closeOnDocumentClick
          closeOnEscape
          closeButton
          onClose={handleCloseStores}
        >
          <div>{renderStoresModalContent(stores)}</div>
        </Modal>
      )}
      {popupState.showFavoritesPopup && favorites.length > 0 && (
        <Modal
          open={popupState.showFavoritesPopup}
          className="w-100"
          header={{
            title: 'Notice: Products Discontinued',
          }}
          closeOnDocumentClick
          closeOnEscape
          closeButton
          onClose={handleCloseFavorites}
        >
          <div>{renderFavoritesModalContent(favorites)}</div>
        </Modal>
      )}
    </>
  );
};

export default DiscontinuedProductsPopupWrapper;
